import React from 'react'
import TransitionLink from 'gatsby-plugin-transition-link'

import { Layout } from '../components/layout'
import Seo from '../components/seo'
import { HeroBanner } from '../components/HeroBanner'
import { routes } from '../URLs'

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Podana strona nie istnieje" />
    <HeroBanner header="Podana strona nie istnieje">
      <TransitionLink
        exit={{
          length: 0.6,
        }}
        entry={{ delay: 0.4, length: 0.1 }}
        to={routes.home}
      >
        Kliknij tutaj aby wrócić do strony głównej
      </TransitionLink>
    </HeroBanner>
  </Layout>
)

export default NotFoundPage
